// import ActiveCampaign from "activecampaign";
import request from "./request";

const PAGES = {
    LANDING_PAGE: "LANDING_PAGE",
    DASHBOARD_SIGNUP_PAGE: "DASHBOARD_SIGNUP_PAGE",
    DASHBOARD_LOGIN_PAGE: "DASHBOARD_LOGIN_PAGE",
    DASHBOARD_PAGE: "DASHBOARD_PAGE",
    DASHBOARD_ASSESSMENTS_PAGE: "DASHBOARD_ASSESSMENTS_PAGE",
    DASHBOARD_ASSESSMENT_PAGE: "DASHBOARD_ASSESSMENT_PAGE",
    DASHBOARD_CANDIDATES_PAGE: "DASHBOARD_CANDIDATES_PAGE",
    DASHBOARD_SCORECARD_PAGE: "DASHBOARD_SCORECARD_PAGE",
    DASHBOARD_TEAM_PAGE: "DASHBOARD_TEAM_PAGE",
    DASHBOARD_LIBRARY_PAGE: "DASHBOARD_LIBRARY_PAGE",
    DASHBOARD_EMAILS_PAGE: "DASHBOARD_EMAILS_PAGE",
    DASHBOARD_IMAGE_UPLOADER_PAGE: "DASHBOARD_IMAGE_UPLOADER_PAGE",
    DASHBOARD_BILLING_PAGE: "DASHBOARD_BILLING_PAGE",
    DASHBOARD_CAREERS_PAGE: "DASHBOARD_CAREERS_PAGE",
    DASHBOARD_INSIGHTS_PAGE: "DASHBOARD_INSIGHTS_PAGE",
    DASHBOARD_INTEGRATIONS_PAGE: "DASHBOARD_INTEGRATIONS_PAGE",
    DASHBOARD_LOGS_PAGE: "DASHBOARD_LOGS_PAGE",
    DASHBOARD_PAIR_PAGE: "DASHBOARD_PAIR_PAGE",
    DASHBOARD_FAQS_PAGE: "DASHBOARD_FAQS_PAGE",
    DASHBOARD_QUESTION_LIBRARY_PAGE: "DASHBOARD_QUESTION_LIBRARY_PAGE",
    DASHBOARD_ASSESSMENT_REQUEST_PAGE: "DASHBOARD_ASSESSMENT_REQUEST_PAGE",
    DASHBOARD_SETTINGS_PAGE: "DASHBOARD_SETTINGS_PAGE",
    CANDIDATE_CHAT_START_PAGE: "CANDIDATE_CHAT_START_PAGE",
    CANDIDATE_LOGIN_PAGE: "CANDIDATE_LOGIN_PAGE",
    CANDIDATE_CHAT_PAGE: "CANDIDATE_CHAT_PAGE",
    CANDIDATE_CHAT_END_PAGE: "CANDIDATE_CHAT_END_PAGE",
    DASHBOARD_SUBSCRIPTION_PAGE: "DASHBOARD_SUBSCRIPTION_PAGE",
    DASHBOARD_INVOICES_PAGE: "DASHBOARD_INVOICES_PAGE",
    DASHBOARD_PAYMENT_METHOD_PAGE: "DASHBOARD_PAYMENT_METHOD_PAGE",
    SCORECARD_PAGE: "SCORECARD_PAGE",
    CANDIDATE_SLACK_PAGE: "CANDIDATE_SLACK_PAGE",
    CANDIDATE_SLACK_LOGIN_PAGE: "CANDIDATE_SLACK_LOGIN_PAGE",
    INTERVIEW_STATUS_PAGE: "INTERVIEW_STATUS_PAGE",
    ACCOUNT_PAGE: "ACCOUNT_PAGE",
    PAIR_SESSION_PAGE: "PAIR_SESSION_PAGE",
    PAIR_LANDING_PAGE: "PAIR_LANDING_PAGE",
    DASHBOARD_CANDIDATES_DISCOVERY: "DASHBOARD_CANDIDATES_DISCOVERY",
};

const ACTIONS = {
    CLICKED: "CLICKED",
    GENERAL: "GENERAL",
};

function _genSub() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

function extractRootDomain(url) {
    return (url || "")
        .match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i)[1]
        .split(".")
        .slice(-2)
        .join(".");
}

function getCookieDomain() {
    return extractRootDomain(location.origin);
}

function getCookie(name) {
    if (!name) {
        return null;
    }
    return (
        decodeURIComponent(
            document.cookie.replace(
                new RegExp(
                    "(?:(?:^|.*;)\\s*" +
                        encodeURIComponent(name).replace(
                            /[\-\.\+\*]/g,
                            "\\$&"
                        ) +
                        "\\s*\\=\\s*([^;]*).*$)|^.*$"
                ),
                "$1"
            )
        ) || null
    );
}

function setCookie(name, value, expire, domain, secure) {
    if (!domain) {
        domain = "." + getCookieDomain();
    }

    var expireString =
        expire === Infinity
            ? " expires=Fri, 31 Dec 9999 23:59:59 GMT"
            : "; max-age=" + expire;
    document.cookie =
        encodeURIComponent(name) +
        "=" +
        value +
        "; path=/;" +
        expireString +
        (domain ? "; domain=" + domain : "") +
        "; secure";
}

function _get_guid_email() {
    const cookieName = "adaface_web_guid_email"; // IMPORTANT: THIS MUST BE SAME AS THE ONE IN adaface-track.html in dashboard

    // Look for the GUID in the currently set cookies
    var guid = getCookie(cookieName);

    return guid;
}

function _save_guid_email(email) {
    const cookieName = "adaface_web_guid_email"; // IMPORTANT: THIS MUST BE SAME AS THE ONE IN adaface-track.html in dashboard

    setCookie(cookieName, email, Infinity);
}

function _getguid() {
    const cookieName = "adaface_web_guid"; // IMPORTANT: THIS MUST BE SAME AS THE ONE IN adaface-track.html in dashboard

    // Look for the GUID in the currently set cookies
    var guid = getCookie(cookieName);

    // We didn't find our guid in the cookies, so we need to generate our own
    if (guid === null) {
        if (typeof defaultGuid === "string") {
            guid = defaultGuid;
        } else {
            guid =
                _genSub() +
                _genSub() +
                "-" +
                _genSub() +
                "-" +
                _genSub() +
                "-" +
                _genSub() +
                "-" +
                _genSub() +
                _genSub() +
                _genSub();
        }

        setCookie(cookieName, guid, Infinity);

        return guid;
    }

    return guid;
}

function sendView({ page, metadata }) {
    if (page) {
        const props = metadata || {};
        props.location = window.localStorage.href;

        // if (amplitude) {
        // console.log("sending event to Amplitude");
        // amplitude.getInstance().logEvent(`${"VIEWED" + "_"}${page}`, props);
        // }

        const gaValue = 1;
        const gaCategory = page;
        const gaAction = `${"VIEWED" + "_"}${page}`;
        const gaLabel = page;

        if (gtag) {
            // console.log("sending event to GA");
            gtag("event", gaAction, {
                name: gaAction,
                value: gaValue,
                action: gaAction,
                label: gaLabel,
                category: page,
                ...props,
            });
        }

        if (window.Intercom) {
            window.Intercom("trackEvent", gaAction, {
                value: gaValue,
                action: gaAction,
                label: gaLabel,
                category: gaCategory,
                name: gaAction,
                ...props,
            });
        }

        // if (window.location.origin.indexOf("localhost") == -1) {
        try {
            request(
                window.location.origin.indexOf("localhost") !== -1
                    ? "http://localhost:3210/logs/track"
                    : window.location.origin.indexOf("tester.adaface.com") !==
                      -1
                    ? "https://test-tasks.adaface.com/logs/track"
                    : "https://tasks.adaface.com/logs/track",
                {
                    method: "POST",
                    body: JSON.stringify({
                        guid_or_email: _get_guid_email() || _getguid(),
                        action: "VIEWED",
                        page_url: window.location.href,
                        referrer_url: document.referrer,
                        name: page,
                        metadata: JSON.stringify(metadata),
                    }),
                    credentials: "omit",
                    withCredentials: false,
                }
            );
        } catch (err) {}
        // }

        var _hsq = (window._hsq = window._hsq || []);
        _hsq.push(["trackPageView"]);
    }
}

function identifyUser({ emailId, auth0Name, profile }) {
    if (emailId) {
        // amplitude.getInstance().setUserId(emailId.toLowerCase());
        const emailIdentifiedFirstTime = !_get_guid_email();

        if (window) {
            window.trackcmp_email = emailId.toLowerCase();
        }

        if (
            // window.location.origin.indexOf("localhost") == -1 &&
            emailIdentifiedFirstTime
        ) {
            _save_guid_email(emailId);
            try {
                request(
                    window.location.origin.indexOf("localhost") !== -1
                        ? "http://localhost:3210/logs/identify"
                        : window.location.origin.indexOf(
                              "tester.adaface.com"
                          ) !== -1
                        ? "https://test-tasks.adaface.com/logs/identify"
                        : "https://tasks.adaface.com/logs/identify",
                    {
                        method: "POST",
                        body: JSON.stringify({
                            guid: _getguid(),
                            emailId,
                            // name: auth0Name,
                            // metadata: JSON.stringify({
                            // 	page_url: window.location.href,
                            // 	referrer_url: document.referrer,
                            // }),
                        }),
                        credentials: "omit",
                        withCredentials: false,
                    }
                );
            } catch (err) {}
        }

        var _hsq = (window._hsq = window._hsq || []);
        _hsq.push([
            "identify",
            {
                email: emailId,
            },
        ]);
    }
}

function sendAction({ action, metadata, name, page }) {
    if (action && name) {
        const props = metadata || {};
        props.location = window.location.href;
        props.page = page;
        // if (amplitude) {
        // amplitude.getInstance().logEvent(`${action}_${name}`, props);
        // }

        const gaValue = 1;
        const gaCategory = page;
        const gaAction = `${action}_${name}`;
        const gaLabel = name;

        if (gtag) {
            gtag("event", gaAction, {
                value: gaValue,
                action: gaAction,
                label: gaLabel,
                category: gaCategory,
                name: gaAction,
                ...props,
            });
        }

        if (window.Intercom) {
            window.Intercom("trackEvent", gaAction, {
                value: gaValue,
                action: gaAction,
                label: gaLabel,
                category: gaCategory,
                name: gaAction,
                ...props,
            });
        }

        if (
            // window.location.origin.indexOf("localhost") == -1 &&
            action === "VIEWED"
        ) {
            try {
                request(
                    window.location.origin.indexOf("localhost") !== -1
                        ? "http://localhost:3210/logs/track"
                        : window.location.origin.indexOf(
                              "tester.adaface.com"
                          ) !== -1
                        ? "https://test-tasks.adaface.com/logs/track"
                        : "https://tasks.adaface.com/logs/track",
                    {
                        method: "POST",
                        body: JSON.stringify({
                            guid_or_email: _get_guid_email() || _getguid(),
                            action: action,
                            page_url: window.location.href,
                            referrer_url: document.referrer,
                            name: `${action}_${name}`,
                            metadata: JSON.stringify(props),
                        }),
                        credentials: "omit",
                        withCredentials: false,
                    }
                );
            } catch (err) {}
        }

        // var _hsq = (window._hsq = window._hsq || []);
        // _hsq.push([
        // 	"trackEvent",
        // 	{
        // 		id: gaAction,
        // 	},
        // ]);
    }
}

const exports = {
    sendView,
    sendAction,
    identifyUser,
    PAGES,
    ACTIONS,
};

export default exports;
